<template>
  <div class="columns has-background-white is-centered">
    <div
      v-if="loading" 
      class="column is-half"
    >    
      <h1 class="title is-size-5 has-text-centered">Please wait as we verify your email</h1>
      <Loader />
    </div>
    <div
      v-else
    >
      
      <div
       v-if="error == null" 
      >
        
        <article
          class="message is-primary">
          <div class="message-header">
            <p>
              Success
            </p>

          </div>
          <div class="message-body">
            Thank you for verifying your email. <br/><br/>
            <button 
              class="button"
              @click="go()"
            >
             Browse deals
            </button>
          </div>
        </article>

      </div>

      <div
       v-else 
      >
        <p>Sorry, we could not verify your email.</p>
        <p class="has-text-danger">{{ error }}</p>
      </div>


    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import api from "@/service/api.js";

export default {
  props: {
    token: {
      type: String,
      required: true
    },
  },
  components: {
    Loader
  },
  data() {
    return {
      loading: true,
      error: null,
    }
  },
  mounted() {
    const params = {
      token: this.token,
    }
    api.confirmEmail(params)
    .then(() => {
      this.loading = false
    })
    .catch(error => {
      this.loading = false
      this.error = error
    })
  },
  methods: {
    go() {
      this.$router.push("/")
    }
  }
}
</script>
